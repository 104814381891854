<template>
  <div class="contain">
    <div class="header-title">
      <!-- <span>{{ pageTitle }}</span>
      <el-button class="return-button" @click="onCancel" v-if="type != 'view'">返回</el-button> -->
      <el-page-header @back="onCancel" :content="pageTitle">
        <template slot="title">
          <i class="el-icon-arrow-left"></i><span>返回</span>
        </template>
      </el-page-header>
    </div>
    <div class="form" v-if="type != 'view'">
      <el-form
        :model="superviseTaskForm"
        :rules="rules"
        style="width: 80%"
        ref="superviseTaskForm"
        :label-width="labelWidth"
        class="demo-ruleForm"
      >
        <el-form-item label="督导模板:" prop="templateId" ref="templateId">
          <el-select
            v-model="superviseTaskForm.templateId"
            class="elInput"
            filterable
            placeholder="请选择"
            @change="handleTemplateChange"
            :disabled="superviseTaskForm.status != 2 && $route.query.type != 'add'"
          >
            <el-option
              v-for="item in templateOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接收范围:" prop="supRangeList" ref="supRangeList">
          <el-select
            v-model="rangeValue"
            class="elInput"
            multiple
            clearable
            placeholder="请选择"
            v-if="jumpType == 'company'"
            @change="handleRangeCompanyChange"
            :disabled="superviseTaskForm.status != 2 && $route.query.type != 'add'"
          >
            <el-option
              v-for="item in rangeOptions"
              :key="item.label"
              :label="item.name"
              :value="item.label"
            >
            </el-option>
          </el-select>
          <el-cascader
            v-if="jumpType == 'headquarters'"
            class="elInput"
            v-model="rangeValue"
            :props="rangeProps"
            :options="rangeOptions"
            clearable
            :show-all-levels="false"
            @change="handleRangeChange"
            :disabled="superviseTaskForm.status != 2 && $route.query.type != 'add'"
            popper-class="choose-cascader"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="岗位:" prop="jobId" ref="jobId">
          <el-select
            v-model="superviseTaskForm.jobId"
            multiple
            class="elInput"
            placeholder="请选择"
            :disabled="superviseTaskForm.status != 2 && $route.query.type != 'add'"
          >
            <el-option
              v-for="item in postOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="10">
            <el-form-item label="定时发布:" prop="isTimed" ref="isTimed">
              <el-switch
                v-model="superviseTaskForm.isTimed"
                @change="handleDelayPublish"
                active-color="#003685"
                inactive-color="#C5C3D8"
                :active-text="!superviseTaskForm.isTimed ? '关' : '开'"
                :inactive-text="''"
                :disabled="superviseTaskForm.status != 2 && $route.query.type != 'add'"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="14" v-if="superviseTaskForm.isTimed">
            <el-form-item label="发布时间:" prop="timedSend" ref="timedSend">
              <el-date-picker
                v-model="superviseTaskForm.timedSend"
                type="datetime"
                placeholder="请选择发布时间"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="handlePublishTime"
                :disabled="superviseTaskForm.status != 2 && $route.query.type != 'add'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="截止日期:" prop="parkDeadline" ref="parkDeadline">
          <el-date-picker
            v-model="superviseTaskForm.parkDeadline"
            class="elInput"
            type="date"
            placeholder="请选择截止日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="handleDateChange"
            :disabled="superviseTaskForm.status != 2 && $route.query.type != 'add'"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="督导主题:"
          prop="supervisionTopic"
          ref="supervisionTopic"
        >
          <el-input
            placeholder="请输入督导主题"
            v-model="superviseTaskForm.supervisionTopic"
            :maxlength="100"
            :disabled="superviseTaskForm.status != 2 && $route.query.type != 'add'"
          ></el-input>
        </el-form-item>
        <div class="dashed-line" v-show="templateInfo.name"></div>
        <el-form-item label="模板名称:" v-show="templateInfo.name">
          <el-input v-model="templateInfo.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="描述:" v-show="templateInfo.name">
          <el-input
            type="textarea"
            :rows="4"
            v-model="templateInfo.description"
            resize="none"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="督导方式:" v-show="templateInfo.name">
          <el-radio-group
            v-model="templateInfo.supervisionMethod"
            :disabled="true"
          >
            <el-radio
              :label="item.value"
              v-for="item in superviseTypeOptions"
              :key="item.value"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="总题数:" v-show="templateInfo.name">
          <el-input v-model="templateInfo.totalQuestions" :disabled="true">
            <template slot="append">道</template>
          </el-input>
        </el-form-item>
        <el-form-item label="总分:" v-show="templateInfo.name">
          <el-input v-model="templateInfo.totalScore" :disabled="true">
            <template slot="append">分</template>
          </el-input>
        </el-form-item>
        <el-form-item label="督办人：">
          <el-button type="primary" @click="addAuth">新增</el-button>
          <el-table
            :header-cell-style="{
              background: '#F1F4F9',
              color: '#5E6D9B',
              fontWeight: '400',
              'text-align': 'center',
            }"
            style="
              width: 100%;
              margin-bottom: 1.04167vw;
              margin-top: 1.04167vw;
              min-height: calc(100% - 5.52083vw);
              overflow-y: scroll;
              overflow: visible;
            "
            :data="superviseTaskForm.createByAuth"
            border
          >
            <el-table-column prop="userName" label="督办人" width="400">
              <template slot-scope="scope">
                <el-autocomplete type="text" v-model="scope.row.userName" :fetch-suggestions="(val,cb)=>querySearchAsync(val,cb,scope.$index)" @select="(val)=>handleSelect(val,scope.$index)"  placeholder="请输入督办人">
                  <template slot-scope="{ item }">
                    <div class="user_name">
                      {{ item.userName }}+{{ item.orgName }}+{{ item.deptName }}
                      <!-- <div class="name">{{ item.userName }}</div>
                      <span class="orgName">{{ item.orgName }}</span>   
                      <span class="postName">{{ item.deptName }}</span> -->
                      <!-- <span class="schoolName">{{ item.schoolName }}</span> -->
                    </div>
                  </template>
                </el-autocomplete>
              </template>
            </el-table-column>
            <el-table-column prop="orgName" label="组织" >
            </el-table-column>
            <el-table-column prop="deptName" label="部门">
            </el-table-column>
            <el-table-column  label="操作" width="80" align="center">
              <template slot-scope="scope">
                <el-button @click="delAuth(scope.$index)" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div class="form-btn">
        <el-button class="cancel" @click="onCancel" :loading="buttonLoading"
          >取消</el-button
        >
        <el-button class="submit" @click="onSubmit" :loading="buttonLoading"
          >发布</el-button
        >
      </div>
    </div>
    <div class="form" v-if="type == 'view'">
      <div class="message-warning">
        <div class="message-body">
          <img :src="meesageWarningIcon" alt="" />
          <div class="message-text">任务下发园校总个数：</div>
          <div class="message-score">{{ schoolNum }}</div>
          <div class="message-text">，已完成初次园校自评个数：</div>
          <div class="message-score">{{ schoolEvaluateNum }}</div>
        </div>
        <div class="message-detail-text" @click="jumpAgencyTaskPage">
          查看详情>
        </div>
      </div>
      <div class="view-body">
        <div class="view-body-title">{{ templateInfo.templateName }}</div>
        <div class="view-body-remark">{{ templateInfo.description }}</div>
        <div class="dashed-line"></div>
        <div class="view-body-content-choose">
          <div class="label">督导内容：</div>
          <div class="item">
            <el-select
              v-model="projectContent"
              placeholder="请选择"
              @change="handleProjectLevelChange"
            >
              <el-option
                v-for="item in projectContentOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="view-body-content-list">
          <div
            class="view-body-content-item"
            v-for="(item, index) in projectContentList"
            :key="item.id"
          >
            <div class="item-title publish">
              <!-- <div class="title" :style="{ width: projectWidth }"> -->
              <div class="title" style="width:40vw">
                {{ item.projectName }}
              </div>
              <div class="number" v-if="index == 0" ref="titleNumber">数量</div>
              <div class="range" v-if="index == 0">占比</div>
            </div>
            <div
              class="item-options"
              v-for="(info, i) in item.optionsList"
              :key="info.id"
            >
              <!-- <div
                class="options-question publish"
                :ref="'question-' + index + '-' + i"
                :style="{ width: projectWidth }"
              > -->
              <div
                class="options-question publish"
                :ref="'question-' + index + '-' + i"
                style="width:40vw"
              >
                {{ info.optionsNum }}、{{ info.projectName }}
              </div>
              <div
                class="options-item"
                v-for="(optionInfo, j) in info.options"
                :key="optionInfo.id"
              >
                <!-- <div
                  class="option-item-info"
                  :ref="'options-' + index + '-' + i + '-' + j"
                  :style="{ width: optionsWidth }"
                > -->
                <div
                  class="option-item-info"
                  :ref="'options-' + index + '-' + i + '-' + j"
                  style="width:40vw"
                >
                  <div class="item-info">
                    <div class="circle"></div>
                    <div class="text">{{ optionInfo.projectName }}</div>
                  </div>
                  <div class="score">{{ optionInfo.score }}分</div>
                </div>
                <div class="number" :style="{ width: numberWidth }">
                  {{ optionInfo.quantity || 0 }}
                </div>
                <div class="range">
                  <el-progress
                    :percentage="optionInfo.percentage || 0"
                  ></el-progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="form-btn">
        <el-button class="cancel" @click="onCancel">返回</el-button>
      </div> -->
    </div>
    <div class="loading-dialog" v-if="loadingDialogVisible">
      <div class="loading-dialog-body">
        <img
          class="close-icon"
          :src="closeIcon"
          alt=""
          @click="handleLoadingClose"
        />
        <div class="loading-dialog-icon">
          <img
            :class="{ loading: loadingDialogVisible }"
            :src="publishLoadingIcon"
            alt=""
          />
        </div>
        <div class="loading-dialog-text">正在发布，请稍后，等待时间较长，您可关闭弹窗在列表中查看发布结果，也可以继续等待</div>
        <el-button  type="primary" class="close-btn" @click="closePage">关闭</el-button>
      </div>
    </div>
    <div class="result-dialog" v-if="resultDialogVisible">
      <div class="result-dialog-body">
        <img
          class="close-icon"
          :src="closeIcon"
          alt=""
          @click="handleResultClose"
          v-if="resultDialogType == 'fail'"
        />
        <div class="result-dialog-icon">
          <img
            :src="
              resultDialogType == 'success'
                ? publishSuccessIcon
                : publishFailIcon
            "
            alt=""
          />
        </div>
        <div class="result-dialog-text">
          {{ resultDialogType == "success" ? "发布成功" : "发布失败！" }}
        </div>
        <div
          class="result-dialog-little-text"
          v-if="resultDialogType == 'fail'"
        >
          以下园校未发现任务接收或处理响应人：
        </div>
        <div class="republish-button" v-if="resultDialogType == 'fail'">
          <el-button class="button" @click="handleErrorFun('batchRefresh')"
            >批量刷新</el-button
          >
          <el-button class="button" @click="onReSubmit">重新发布</el-button>
        </div>
        <l-table
          v-if="resultDialogType == 'fail'"
          :columns="jumpType == 'company' ? columnsCompany : columns"
          :dataSource="errorTableData"
          :pagination="pageFlag"
          :ipagination="page"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :loading="tableLoading"
          :showXh="true"
          :height="'20.83333vw'"
          :autoHeight="false"
        >
          <el-table-column
            prop="parkName"
            label="园校"
            align="center"
            slot="parkName"
          ></el-table-column>
          <el-table-column
            prop="jobName"
            label="岗位"
            align="center"
            slot="jobName"
          ></el-table-column>
          <el-table-column
            prop="receiverName"
            label="接收人"
            align="center"
            width="90"
            slot="receiverName"
          >
            <template slot-scope="scope">
              <span
                :style="{
                  color: scope.row.receiver == '' ? '#C6000B' : '#010334',
                }"
                >{{
                  scope.row.receiver == "" ? "无" : scope.row.receiverName
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="branchAuditName"
            label="分公司审批人"
            align="center"
            width="120"
            slot="branchAuditName"
          >
            <template slot-scope="scope">
              <span
                :style="{
                  color: scope.row.branchAudit == '' ? '#C6000B' : '#010334',
                }"
                >{{
                  scope.row.branchAudit == "" ? "无" : scope.row.branchAuditName
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="groupAuditName"
            label="总部审批人"
            align="center"
            width="120"
            slot="groupAuditName"
          >
            <template slot-scope="scope">
              <span
                :style="{
                  color: scope.row.groupAudit == '' ? '#C6000B' : '#010334',
                }"
                >{{
                  scope.row.groupAudit == "" ? "无" : scope.row.groupAuditName
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            slot="handle"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                class="table-handle"
                @click="handleErrorFun('refresh', scope.row, scope.$index)"
                type="text"
                >刷新</el-button
              >
            </template>
          </el-table-column>
        </l-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import publishLoadingIcon from "@/assets/img/publish-loading-icon.png";
import publishSuccessIcon from "@/assets/img/setting-success-icon.png";
import publishFailIcon from "@/assets/img/publish-fail-icon.png";
import closeIcon from "@/assets/img/icon_close.png";
import meesageWarningIcon from "@/assets/img/message-warning-icon.png";
import LTable from "@/components/tableComponent/index.vue";
export default {
  name: "superviseTaskDetail",
  components: { LTable },
  data() {
    var validateEndDate = (rule, value, callback) => {
      let nowDate = new Date();
      nowDate.setHours(17);
      nowDate.setMinutes(0);
      nowDate.setSeconds(0);
      if (value == "") {
        callback(new Error("请选择截止日期"));
      } else if (
        value != "" &&
        new Date(value + " 18:00:00").getTime() < nowDate.getTime() &&
        (this.type == "add" ||
          (this.type == "edit" && this.oldParkDeadline != value))
      ) {
        callback(new Error("截止日期不能小于当前日期"));
      } else if (
        value != "" &&
        new Date(value + " 18:00:00").getTime() <
          new Date(this.superviseTaskForm.timedSend).getTime() &&
        this.superviseTaskForm.isTimed &&
        this.superviseTaskForm.timedSend !== ""
      ) {
        callback(new Error("截止日期不可小于发布时间"));
      } else {
        callback();
      }
    };
    var validatePublishDate = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请选择发布时间"));
      } else if (
        value != "" &&
        new Date(value).getTime() < new Date().getTime() &&
        (this.type == "add" ||
          (this.type == "edit" && this.oldTimedSend != value))
      ) {
        callback(new Error("发布时间不能小于当前日期"));
      } else {
        callback();
      }
    };
    return {
      superviseTaskForm: {
        templateId: "", //督导模板
        supRangeList: [], //接收范围
        jobId: [], //岗位
        isTimed: false, //是否定时发布
        timedSend: "", //发布时间
        parkDeadline: "", //截止日期
        supervisionTopic: "", //督导主题
        createByAuth:[]
      },
      rules: {
        templateId: [
          { required: true, message: "请选择模板", trigger: "change" },
        ],
        supRangeList: [
          {
            required: true,
            type: "array",
            message: "请选择接收范围",
            trigger: "change",
          },
        ],
        jobId: [
          {
            required: true,
            type: "array",
            message: "请选择岗位",
            trigger: "change",
          },
        ],
        isTimed: [
          { required: true, message: "请选择是否定时发布", trigger: "change" },
        ],
        timedSend: [
          { required: true, validator: validatePublishDate, trigger: "change" },
        ],
        parkDeadline: [
          { required: true, validator: validateEndDate, trigger: "change" },
        ],
        supervisionTopic: [
          { required: true, message: "请输入督导主题", trigger: "blur" },
        ],
      },
      id: null, //督导任务发布id
      type: "", //编辑类型
      jumpType: "", //跳转类型
      pageTitle: "", //页面标题

      superviseTypeOptions: [
        {
          name: "远程督导",
          value: "1",
        },
        {
          name: "现场督导",
          value: "2",
        },
      ], //督导方式下拉
      templateOptions: [], //模板下拉
      rangeOptions: [], //接收范围数据
      rangeProps: {
        value: "label",
        label: "name",
        children: "child",
        multiple: true,
      }, //接收范围props
      rangeValue: [],

      templateInfo: {}, //模板信息
      projectContent: "", //督导内容下拉选中项
      projectContentOptions: [], //督导内容下拉list
      projectContentList: [], //督导内容与选项list

      resultStatus: "", //发布结果
      statusSetIntervalObject: null, //循环请求对象

      oldParkDeadline: "", //编辑时请求回来的截止日期
      oldTimedSend: "", //编辑时请求回来的发布时间

      buttonLoading: false, //按钮loading
      loadingDialogVisible: false, //发布加载loading
      resultDialogVisible: false, //结果弹窗标识
      resultDialogType: "", //结果弹窗类型

      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "园校",
          slotName: "parkName",
        },
        {
          label: "岗位",
          slotName: "jobName",
        },
        {
          label: "接收人",
          slotName: "receiverName",
        },
        {
          label: "分公司审批人",
          slotName: "branchAuditName",
        },
        {
          label: "总公司审批人",
          slotName: "groupAuditName",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      columnsCompany: [
        {
          label: "园校",
          slotName: "parkName",
        },
        {
          label: "岗位",
          slotName: "jobName",
        },
        {
          label: "接收人",
          slotName: "receiverName",
        },
        {
          label: "分公司审批人",
          slotName: "branchAuditName",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      errorTableData: [], //表单数据

      schoolNum: "", //任务下发园校格式
      schoolEvaluateNum: "", //任务自评园校个数

      allprojectWidth: "", //整体宽度
      numberWidth: "auto", //选项number宽度

      publishLoadingIcon, //发布loadingicon
      publishSuccessIcon, //发布成功icon
      publishFailIcon, //发布失败icon
      closeIcon, //关闭icon
      meesageWarningIcon, //信息提醒icon

      labelWidth: "130px",
      taskSupervisionTopic:'',
    };
  },
  computed: {
    ...mapState({
      fileTypeList: (state) => state.common.fileTypeList,
      postOptions: (state) => state.common.postList,
    }),
    projectWidth() {
      return this.allprojectWidth === "auto" && this.allprojectWidth === ""
        ? this.allprojectWidth
        : this.allprojectWidth + "px";
    },
    optionsWidth() {
      return this.allprojectWidth === "auto" && this.allprojectWidth === ""
        ? this.allprojectWidth
        : this.allprojectWidth - 32 + "px";
    },
  },
  watch: {
    resultStatus(val) {
      if (val === true) {
        clearInterval(this.statusSetIntervalObject);
        this.statusSetIntervalObject = null;
        this.loadingDialogVisible = false;
        
        console.log(222);
        this.$nextTick(() => {
          this.resultDialogVisible = true;
          this.resultDialogType = "success";
          setTimeout(() => {
            this.resultDialogVisible = false;
            this.resultDialogType = "";
            if (this.jumpType == "company") {
              this.$router.push("/superviseTaskCompany");
            } else {
              this.$router.push("/superviseTask");
            }
          }, 3000);
        });
      } else if (val === false) {
        clearInterval(this.statusSetIntervalObject);
        this.statusSetIntervalObject = null;
        this.loadingDialogVisible = false;
        console.log(333);
        this.$nextTick(() => {
          this.resultDialogVisible = true;
          this.resultDialogType = "fail";
          this.tableLoading = true;
          this.getErrorList();
        });
      }
    },
  },
  //路由进入前改变title
  beforeRouterEnter(to, from, next) {
    if (to.query.type == "edit") {
      to.meta.title = "督导任务发布编辑";
    } else if (to.query.type == "add") {
      to.meta.title = "督导任务发布新增";
    } else {
      to.meta.title = "督导任务发布详情";
    }
    next();
  },
  async created() {
    if (this.$route.query.type == "edit") {
      this.pageTitle = "督导任务发布编辑";
    } else if (this.$route.query.type == "add") {
      this.pageTitle = "督导任务发布新增";
    } else {
      this.pageTitle = "督导任务发布详情";
    }

    let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList")
      ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList"))
      : null;
    if (chooseMenuInfoList) {
      chooseMenuInfoList.map((item) => {
        if (item.path == this.$route.path) {
          item.meta.title = this.pageTitle;
        }
      });
      let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList));
      breadCrumbList.shift();
      console.log(breadCrumbList);
      this.$store.commit("common/getBreadcrumbList", breadCrumbList || []);
    }

    this.type = this.$route.query.type;
    this.jumpType = this.$route.query.jumpType;
    this.id = this.$route.query.id;
    if (this.type == "view") {
      await this.getTaskLevelOptions();
      this.getTaskViewInfo();
    } else {
      await this.getRangeList();
      await this.getTemplateOptions();
      if (this.$route.query.id) {
        this.getTaskInfo();
      }
    }
  },
  mounted() {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "90px";
      } else {
        this.labelWidth = (130 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "90px";
    } else {
      this.labelWidth = (130 / 1920) * window.innerWidth + "px";
    }
    this.taskSupervisionTopic = this.$route.query.supervisionTopic
  },
  methods: {
    querySearchAsync(queryString, cb,index){
      if(!queryString){
        cb([])
        return
      }
      this.$api.getUserByName({
        userName:queryString
      }).then(res=>{
        setTimeout(()=>{
          cb(res.data.data)
        },200)
      })
    },
    handleSelect(row,index){
      this.superviseTaskForm.createByAuth[index] = row
    },
    // 新增督办人
    addAuth(){
      this.superviseTaskForm.createByAuth.push({
        deptLabel: '',
        deptName: '',
        id: '',
        orgName: '',
        postLabel: '',
        postName: '',
        roleName: '',
        schoolLabel: '',
        schoolName: '',
        userId: '',
        userName: '',
        userNo: '',
        userPostLabel: '',
      })
    },
    // 删除督办人
    delAuth(index){
      this.superviseTaskForm.createByAuth.splice(index,1)
    },
    //获取接收范围下拉
    async getRangeList() {
      if (this.jumpType == "headquarters") {
        await this.$api
          .getSchoolForTree()
          .then((res) => {
            if (res.data.code == 0) {
              this.rangeOptions = res.data.data || [];
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {});
      } else if (this.jumpType == "company") {
        await this.$api
          .getCompanyDept({ type: "NODE_SCHOOL" })
          .then((res) => {
            if (res.data.code == 0) {
              this.rangeOptions = res.data.data || [];
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {});
      }
    },
    //获取模板下拉信息
    async getTemplateOptions() {
      let param = { level: this.jumpType == "headquarters" ? "1" : "" };
      await this.$api
        .getTaskTemplateList(param)
        .then((res) => {
          if (res.data.code == 0) {
            this.templateOptions = res.data.data;
            if (this.$route.query.templateId) {
              this.superviseTaskForm.templateId = Number(
                this.$route.query.templateId
              );
              this.handleTemplateChange(Number(this.$route.query.templateId));
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    //获取任务详情
    getTaskInfo() {
      this.$api.getTaskInfoById(this.id).then((res) => {
        if (res.data.code == 0) {
          let result = res.data.data;
          let rangeList = [];
          let formRangeList = [];
          if (result.supRangeList && result.supRangeList.length > 0) {
            if (this.jumpType == "headquarters") {
              result.supRangeList.map((item) => {
                let list = [item.orgId, item.parkId];
                let info = {
                  orgId: item.orgId,
                  orgName: item.orgName,
                  parkId: item.parkId,
                  parkName: item.parkName,
                  level: item.level,
                };
                rangeList.push(list);
                formRangeList.push(info);
              });
            } else if (this.jumpType == "company") {
              result.supRangeList.map((item) => {
                let info = {
                  orgId: "",
                  orgName: "",
                  parkId: item.parkId,
                  parkName: item.parkName,
                  level: item.level,
                };
                rangeList.push(item.parkId);
                formRangeList.push(info);
              });
            }
          }
          this.superviseTaskForm = {
            templateId: result.templateId, //督导模板
            supRangeList: formRangeList, //接收范围
            jobId: result.jobId ? result.jobId.split(",") : [], //岗位
            isTimed: result.isTimed === "1" ? true : false, //是否定时发布
            timedSend: result.timedSend || "", //发布时间
            parkDeadline: result.parkDeadline, //截止日期
            supervisionTopic: result.supervisionTopic, //督导主题
            createByAuth:JSON.parse(result.createByAuth)
          };
          console.log('createByAuth',this.superviseTaskForm.createByAuth);
          this.oldParkDeadline = result.parkDeadline;
          this.oldTimedSend = result.timedSend || "";
          let templateInfo = this.templateOptions.find((info) => {
            return info.id == result.templateId;
          });
          if (templateInfo) {
            this.templateInfo = templateInfo;
          }
          this.rangeValue = rangeList;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取查看任务的一级督导下拉
    async getTaskLevelOptions() {
      await this.$api
        .getTaskProjectFirstLevelById(this.id)
        .then((res) => {
          if (res.data.code == 0) {
            let list = []
              .concat([{ id: "", name: "全部" }])
              .concat(res.data.data);
            this.projectContentOptions = list;
            // this.projectContent = res.data.data && res.data.data.length > 0 ? res.data.data[0].id : ''
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    //查看任务详情
    getTaskViewInfo() {
      this.$api
        .getTaskViewInfoById(this.id, { projectId: this.projectContent })
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res.data.data);
            let dataInfo = res.data.data;
            this.templateInfo.templateName = dataInfo.templateName;
            this.templateInfo.description = dataInfo.description;
            this.schoolNum = dataInfo.parkNum;
            this.schoolEvaluateNum = dataInfo.doneNum;
            let list = dataInfo.checkOneList;
            let topicNum = 1;
            list.map((item) => {
              if (item.fourLevelProject && item.fourLevelProject.length > 0) {
                item.fourLevelProject.map((info) => {
                  info.optionsNum = topicNum;
                  topicNum++;
                  if (info.options && info.options.length > 0) {
                    info.options.map((optionInfo) => {
                      optionInfo.projectName =
                        optionInfo.projectName.split(" ")[0];
                      optionInfo.percentage = optionInfo.percentage
                        ? Number(optionInfo.percentage)
                        : 0;
                    });
                  }
                });
              }
              item.optionsList = item.fourLevelProject;
            });
            console.log(list);
            this.projectContentList = list;
            setTimeout(() => {
              if (
                this.allprojectWidth === "auto" ||
                this.allprojectWidth === ""
              ) {
                this.refreshWidth();
              }
            }, 400);
          } else {
            // this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    //处理查看督导内容下拉变化事件
    handleProjectLevelChange(val) {
      this.projectContent = val;
      this.getTaskViewInfo();
    },
    //获取长度
    refreshWidth() {
      let width = null;
      this.projectContentList.map((item, index) => {
        if (item.optionsList && item.optionsList.length > 0) {
          item.optionsList.map((info, i) => {
            console.log(this.$refs["question-" + index + "-" + i][0]);
            if (width === null) {
              width = this.$refs["question-" + index + "-" + i][0].clientWidth;
            } else if (
              width < this.$refs["question-" + index + "-" + i][0].clientWidth
            ) {
              width = this.$refs["question-" + index + "-" + i][0].clientWidth;
            }
            if (info.options && info.options.length > 0) {
              info.options.map((optionInfo, j) => {
                if (
                  width <
                  this.$refs["options-" + index + "-" + i + "-" + j][0]
                    .clientWidth
                ) {
                  width =
                    this.$refs["options-" + index + "-" + i + "-" + j][0]
                      .clientWidth;
                }
              });
            }
          });
        }
      });
      console.log(width);
      this.allprojectWidth = width === null ? "unset" : width + 100;
      this.numberWidth = this.$refs.titleNumber[0].clientWidth + "px";
      console.log(this.allprojectWidth);
    },
    //跳转查看待办任务
    jumpAgencyTaskPage() {
      sessionStorage.setItem(
        "supervisionTopic",
        this.superviseTaskForm.supervisionTopic
      );
     
      if (this.jumpType == "headquarters") {
        this.$router.push({ path: "/agencyTask",query:{taskSupervisionTopic:this.taskSupervisionTopic} });
      } else if (this.jumpType == "company") {
        this.$router.push({ path: "/agencyTaskCompany",query:{taskSupervisionTopic:this.taskSupervisionTopic} });
      }
    },
    //改变模板
    handleTemplateChange(val) {
      if (val) {
        let templateInfo = this.templateOptions.find((item) => {
          return item.id == val;
        });
        this.templateInfo = JSON.parse(JSON.stringify(templateInfo));
        if (this.superviseTaskForm.parkDeadline) {
          let dateList = this.superviseTaskForm.parkDeadline
            .split(" ")[0]
            .split("-");
          this.superviseTaskForm.supervisionTopic =
            dateList[0] +
            "年" +
            dateList[1] +
            "月" +
            dateList[2] +
            "日 " +
            templateInfo.name;
        }
      }
    },
    //改变接收范围-分公司
    handleRangeCompanyChange(val) {
      let list = [];
      val.map((valInfo) => {
        let school = this.rangeOptions.find((schoolInfo) => {
          return schoolInfo.label == valInfo;
        });
        let info = {
          orgId: "",
          orgName: "",
          parkId: valInfo,
          parkName: school.name,
          level: "2",
        };
        list.push(info);
      });
      this.superviseTaskForm.supRangeList = list;
    },
    //改变接收范围
    handleRangeChange(val) {
      console.log(val);
      let list = [];
      val.map((valInfo) => {
        let firstInfo = this.rangeOptions.find((companyInfo) => {
          return companyInfo.label == valInfo[0];
        });
        let secondInfo = firstInfo.child.find((schoolInfo) => {
          return schoolInfo.label == valInfo[1];
        });
        let info = {
          orgId: valInfo[0],
          orgName: firstInfo.name,
          parkId: valInfo[1],
          parkName: secondInfo.name,
          level: "1",
        };
        list.push(info);
      });
      this.superviseTaskForm.supRangeList = list;
    },
    //改变定时发布开关
    handleDelayPublish(val) {
      if (!val) {
        this.superviseTaskForm.timedSend = "";
        if (this.superviseTaskForm.parkDeadline) {
          this.$refs.superviseTaskForm.clearValidate("parkDeadline");
        }
      }
    },
    //改变发布时间
    handlePublishTime(val) {
      console.log(val);
      if (val) {
        if (this.superviseTaskForm.parkDeadline) {
          this.$refs.superviseTaskForm.validateField(
            "parkDeadline",
            (errorMessage) => {
              if (errorMessage) {
                this.$refs.parkDeadline.$el.scrollIntoView({
                  //滚动到指定节点
                  block: "center", //值有start,center,end，nearest，当前显示在视图区域中间
                  behavior: "smooth", //值有auto、instant,smooth，缓动动画（当前是慢速的）
                });
              }
            }
          );
        }
      } else {
        if (this.superviseTaskForm.parkDeadline) {
          this.$refs.superviseTaskForm.clearValidate("parkDeadline");
        }
      }
    },
    //改变截止日期
    handleDateChange(val) {
      if (val && this.templateInfo.name) {
        let dateList = val.split("-");
        this.superviseTaskForm.supervisionTopic =
          dateList[0] +
          "年" +
          dateList[1] +
          "月" +
          dateList[2] +
          "日 " +
          this.templateInfo.name;
      }
    },
    //取消
    onCancel() {
      this.$router.back(-1);
    },
    //刷新发布状态
    refreshPublishStatus() {
      this.statusSetIntervalObject = setInterval(async () => {
        await this.$api
          .getTaskPublishStatus(this.id)
          .then((res) => {
            if (res.data.code == 0) {
              console.log(111);
              if (res.data.data === "3") {
                this.resultStatus = true;
              } else if (res.data.data === "4") {
                this.resultStatus = false;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {});
      }, 300);
    },
    //处理loading关闭
    handleLoadingClose() {
      this.loadingDialogVisible = false;
      clearInterval(this.statusSetIntervalObject);
      this.$nextTick(() => {
        this.statusSetIntervalObject = null;
        if (this.jumpType == "company") {
          this.$router.push("/superviseTaskCompany");
        } else {
          this.$router.push("/superviseTask");
        }
      });
    },
    //提交
    onSubmit() {
      this.$refs.superviseTaskForm.validate((valid, object) => {
        if (valid) {
          console.log(this.superviseTaskForm);
          this.buttonLoading = true;
          if (!this.superviseTaskForm.isTimed) {
            this.loadingDialogVisible = true;
          }
          let url = "";
          let jobName = [];
          this.superviseTaskForm.jobId.map((item) => {
            let jobInfo = this.postOptions.find((info) => {
              return info.value == item;
            });
            jobName.push(jobInfo.name);
          });
          let arr = []
          arr = this.superviseTaskForm.createByAuth.filter(e=> {
            if(e.userName){
              return e
            }
          })
          let params = {
            templateId: this.superviseTaskForm.templateId, //督导模板
            supRangeList: this.superviseTaskForm.supRangeList, //接收范围
            jobId: this.superviseTaskForm.jobId.join(","), //岗位
            jobName: jobName.join(","), //岗位名称
            isTimed: this.superviseTaskForm.isTimed ? "1" : "0", //是否定时发布
            timedSend: this.superviseTaskForm.timedSend, //发布时间
            parkDeadline: this.superviseTaskForm.parkDeadline, //截止日期
            supervisionTopic: this.superviseTaskForm.supervisionTopic, //督导主题
            createByAuth: JSON.stringify(arr),
            editStatus:this.superviseTaskForm.status
          };
          params.orgId = this.jumpType == "headquarters" ? "" : "";
          params.orgName = this.jumpType == "headquarters" ? "" : "";
          params.level = this.jumpType == "headquarters" ? "1" : "2";
          if (this.type == "add") {
            url = "/supervise/order-list/save";
          } else if (this.type == "edit") {
            url = "/supervise/order-list/updateById";
            params.id = this.id;
          }
          this.$api
            .handleTaskFun(url, params)
            .then((res) => {
              if (res.data.code == 0) {
                if (!this.superviseTaskForm.isTimed) {
                  this.buttonLoading = false;
                  if (this.type == "add") {
                    this.id = res.data.data;
                  }
                  this.refreshPublishStatus();
                } else {
                  this.$message({
                    type: "success",
                    message:
                      this.type == "edit"
                        ? "编辑督导发布任务成功!"
                        : "新增督导发布任务成功!",
                    duration: 2000,
                    onClose: () => {
                      this.buttonLoading = false;
                      if (this.jumpType == "company") {
                        this.$router.push("/superviseTaskCompany");
                      } else {
                        this.$router.push("/superviseTask");
                      }
                    },
                  });
                }
              } else {
                this.buttonloading = false;
                this.$message.error(res.data.msg);
                if (!this.superviseTaskForm.isTimed) {
                  this.loadingDialogVisible = false;
                }
              }
            })
            .catch(() => {
              this.buttonloading = false;
              if (!this.superviseTaskForm.isTimed) {
                this.loadingDialogVisible = false;
              }
            });
        } else {
          console.log(object);

          for (let i in object) {
            let dom = this.$refs[i];
            dom.$el.scrollIntoView({
              //滚动到指定节点
              block: "center", //值有start,center,end，nearest，当前显示在视图区域中间
              behavior: "smooth", //值有auto、instant,smooth，缓动动画（当前是慢速的）
            });
            break;
          }
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 保存中，返回上一页
    closePage(){
      this.loadingDialogVisible = false
      if (this.jumpType == "company") {
        this.$router.push("/superviseTaskCompany");
      } else {
        this.$router.push("/superviseTask");
      }
    },
    //重新发布操作
    onReSubmit() {
      this.resultDialogVisible = false;
      this.$nextTick(() => {
        this.errorTableData = [];
        this.page.size = 10;
        this.page.num = 1;
        this.page.total = 0;
        this.resultDialogType = "";
        this.resultStatus = "";
        this.loadingDialogVisible = true;
        this.$api
          .republishTask(this.id)
          .then((res) => {
            if (res.data.code == 0) {
              this.refreshPublishStatus();
            } else {
              this.loadingDialogVisible = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {
            this.loadingDialogVisible = false;
          });
      });
    },
    //关闭dialog
    handleResultClose() {
      this.resultDialogVisible = false;
      this.$nextTick(() => {
        this.errorTableData = [];
        this.page.size = 10;
        this.page.num = 1;
        this.page.total = 0;
        this.resultDialogType = "";
      });
    },
    //获取错误信息list
    getErrorList() {
      this.$api
        .getTaskFailList({ listId: this.id })
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code == 0) {
            let list = res.data.data.records;
            this.errorTableData = list;
            this.page.total = res.data.data.total;
            if (res.data.data.total > 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    //刷新操作
    handleErrorFun(type, row, index) {
      if (type == "refresh") {
        this.$api
          .refreshTaskFailList(row.id)
          .then((res) => {
            if (res.data.code == 0) {
              let info = res.data.data;
              this.$set(this.errorTableData, index, info);
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {});
      } else if (type == "batchRefresh") {
        this.$api
          .batchRefreshTaskFailList(this.id)
          .then((res) => {
            if (res.data.code == 0) {
              this.tableLoading = true;
              this.getErrorList();
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {});
      }
    },
    /** 分页 */
    handleCurrentChange(val) {
      console.log(val);
      this.page.num = val;
      this.tableLoading = true;
      this.getErrorList();
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.size = val;
      this.page.num = 1;
      this.tableLoading = true;
      this.getErrorList();
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "90px";
      } else {
        this.labelWidth = (130 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .header-title {
    width: 100%;
    color: #3d3e4f;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .return-button {
      &.el-button:focus {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
      &.el-button:hover {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
    }
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #003685;
    background: #003685;
  }

  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #003685;
  }

  /deep/.el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }
  .dashed-line {
    width: 100%;
    height: 0;
    border-bottom: 1px dashed #e2e7f0;
    margin: 40px 0;
  }
  .form {
    width: calc(100% - 80px);
    padding: 0 40px;
    min-height: 696px;
    .elInput {
      width: 100%;
    }
    .form-title {
      width: 100%;
      color: #3d3e4f;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 40px;
    }
    /deep/.el-form-item .el-select {
      width: 100%;
      // height: 40px;
    }

    /deep/.el-form-item__label {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }
    /deep/.el-input--suffix .el-input__inner {
      padding-right: 30px;
      height: 40px ;
    }
    /deep/.el-input--suffix .el-input__inner,
    /deep/.el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/.el-textarea__inner {
      height: 80px;
    }
    /deep/.el-switch__label.is-active {
      color: #010334;
    }
    /deep/.el-switch__label {
      color: #010334;
    }
    /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
      color: #003685;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #003685 !important;
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__inner:hover {
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__label {
      color: #010334 !important;
    }
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 130px;

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
  /deep/.el-table {
    // margin-top: 14px;
  }
  /deep/.el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
  }
  /deep/.el-table th:first-child > .cell {
    border-left: transparent;
  }

  .options-question {
    line-height: 20px;
  }

  .publish .number{
    margin-left: 26px;
  }
}
.el-autocomplete{
  width: 100%;
}
.user_name{
  display: flex;
  justify-content: space-between;
  .name,.postName,.orgName{
    width: 33.33%;
  }
  // .postName{
  //   width: 50%;
  // }
}
.loading-dialog-text{
  padding:0 40px;
  line-height: 50px;
}
.close-btn{
  margin: 30px 0;
}
.loading-dialog-body{
  padding-top:30px;
  min-height: 300px;
}
</style>
